<template>
  <div class="pda-report">
    <div class="head">
      <div
        class="rtit"
        style="padding-right: 20px; font-size: 0.2rem"
        v-loading="loading"
      >
        {{
          `${$store.state.schoolInfo.atteSchool.schoolName}-${$route.query.schoolReport}`
        }}-系统平台班级成长测评报告
        <!-- <label for="" style="float: right">学期测试</label> -->
      </div>
      <div class="cont">
        园所测评报告将该园所在系统平台内每个班级的测试情况汇总并分析，按照基础和运动测试分类，从完成率和合格率维度进行展示，以便园所更好地开展足球特色工作。
      </div>
    </div>
    <div class="head" v-loading="loading">
      <div class="rtit">测试概况</div>
      <div class="cont">
        本次测试园所共有{{ gkInfo.gradeSum }}个班参加，{{
          gkInfo.stuSum
        }}个幼儿参与；共有男生{{ gkInfo.boySum }}人，女生{{
          gkInfo.girlSum
        }}人；小班{{ gkInfo.grade2StuCount }}人，中班{{
          gkInfo.grade1StuCount
        }}人，大班{{ gkInfo.grade0StuCount }}人。总数据量信息为{{
          gkInfo.TestSum
        }}条，分别为体质测试数据{{ gkInfo.TestType0 }}条，运动测试数据{{
          gkInfo.TestType1
        }}条。
      </div>
    </div>
    <div class="testchart">
      <div id="testchart-1"></div>
      <div id="testchart-2"></div>
    </div>
    <div class="head">
      <div class="rtit2"><span>测试完成率</span></div>
      <table class="layui-table">
        <thead>
          <tr>
            <th></th>
            <th v-for="(item, index) in testingLogs0" :key="index">
              {{ item.testingFoundation.title }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><b>园所</b></td>
            <td v-for="(item, index) in testingLogs0" :key="index">
              {{ item.avgData }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="head">
      <table class="layui-table style-4">
        <thead>
          <tr>
            <th></th>
            <th v-for="(item, index) in testingLogs1" :key="index">
              {{ item.testingSports.title }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><b>园所</b></td>
            <td v-for="(item, index) in testingLogs1" :key="index">
              {{ item.avgData }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="head">
      <div class="rtit2"><span>评价占比</span></div>
      <table class="layui-table style-5">
        <thead>
          <tr>
            <th></th>
            <th v-for="(item, index) in pjzbList.dbselectSix" :key="index">
              {{ item.title }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><b>合格率</b></td>
            <td v-for="(item, index) in pjzbList.dbselectSix" :key="index">
              {{ item.perValue == "0%" ? "--" : item.perValue }}
            </td>
          </tr>
          <tr>
            <td><b>优秀率</b></td>
            <td v-for="(item, index) in pjzbList.dbselectEight" :key="index">
              {{ item.perValue == "0%" ? "--" : item.perValue }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="head">
      <div class="rtit2"><span>项目均值</span></div>
      <div class="cont" style="border: 0; text-align: center">
        项目均值主要由全国平均值、全省平均值、全园平均值组成。
      </div>
      <div class="avgbar" id="avgbar"></div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
export default {
  name: "pda_report_page3",
  components: {},
  data() {
    return {
      gkInfo: {},
      testingLogs0: [],
      testingLogs1: [],
      pjzbList: [],
      loading: true,
    };
  },
  created() {},
  mounted() {
    this.getGK();
    this.getWCL();
    this.getAVG();
  },
  methods: {
    // 体质测试
    getGK() {
      let data = {
        semesterId: this.$route.query.semesterId,
        schoolId: this.$route.query.schoolId,
      };
      this.api.pes.findSchoolReports(data).then((res) => {
        this.loading = false;
        this.gkInfo = res.data;
        this.$nextTick(() => {
          this.echarts1();
          this.echarts2();
        });
        this.getPJZB();
      });
    },
    getWCL() {
      let data = {
        semesterId: this.$route.query.semesterId,
        schoolId: this.$route.query.schoolId,
        isEnd: this.$route.query.isEnd,
      };
      this.api.pes.findSchoolTestPercent(data).then((res) => {
        this.testingLogs0 = res.data.testingLogs0 || [];
        this.testingLogs1 = res.data.testingLogs1 || [];
      });
    },
    getPJZB() {
      let data = {
        semesterId: this.$route.query.semesterId,
        schoolId: this.$route.query.schoolId,
        isEnd: this.$route.query.isEnd,
      };
      this.api.pes.findPerEvaluation(data).then((res) => {
        if (res.flag) {
          this.pjzbList = res.data;
        }
      });
    },
    async getAVG() {
      let data1 = {
        semesterId: this.$route.query.semesterId,
        isEnd: this.$route.query.isEnd,
      };
      let data2 = {
        semesterId: this.$route.query.semesterId,
        schoolId: this.$route.query.schoolId,
        isEnd: this.$route.query.isEnd,
      };
      let countryList = await this.api.pes.findCountyTestSportsAvg(data1);
      let provinceList = await this.api.pes.findProvinceTestSportsAvg(data2);
      let schoolList = await this.api.pes.findSchoolTestSportsAvg(data2);

      // 处理平均数据
      let arr = [];
      countryList.data.forEach((item1) => {
        provinceList.data.forEach((item2) => {
          schoolList.data.forEach((item3) => {
            if (
              item1.testingSports.testNo === item2.testingSports.testNo &&
              item1.testingSports.testNo === item3.testingSports.testNo
            ) {
              let data = {
                title: item1.testingSports.title,
                country: item1.avgData,
                province: item2.avgData,
                school: item3.avgData,
              };
              if (data.title === "平衡") {
                let left = { ...data };
                let right = { ...data };
                left.title = "平衡-左";
                left.country = left.country.split("/")[0] || 0;
                left.province = left.province.split("/")[0] || 0;
                left.school = left.school.split("/")[0] || 0;
                right.title = "平衡-右";
                right.country = right.country.split("/")[1] || 0;
                right.province = right.province.split("/")[1] || 0;
                right.school = right.school.split("/")[1] || 0;
                arr.push(left);
                arr.push(right);
              } else {
                arr.push(data);
              }
            }
          });
        });
      });
      this.echarts3(arr);
    },
    echarts1() {
      var data_1 = [
        { value: this.gkInfo.girlSum, name: "女生" },
        { value: this.gkInfo.boySum, name: "男生" },
      ];
      console.log(data_1);
      var testchart_1 = echarts.init(document.getElementById("testchart-1"));
      var option_1 = {
        title: {
          text: "集体中男女比例",
          left: "center",
          top: "bottom",
          textStyle: {
            color: "#000",
            fontSize: 14,
          },
        },
        tooltip: {
          trigger: "item",
          formatter: "{b}: {c} ({d}%)",
        },
        legend: {
          left: 0,
          top: 0,
          orient: "vertical",
        },
        grid: {
          left: 0,
          right: 0,
        },
        series: [
          {
            type: "pie",
            radius: ["30%", "50%"],
            avoidLabelOverlap: false,
            label: {
              formatter: "{d}%",
            },
            color: ["#db606f", "#284691"],
            data: data_1,
          },
        ],
      };
      if (option_1 && typeof option_1 === "object") {
        testchart_1.setOption(option_1, true);
      }
    },
    echarts2() {
      var data_2 = [
        {
          name: "男生",
          type: "bar",
          barWidth: 25,
          stack: "总数",
          data: [
            this.gkInfo.grade2StuCount - this.gkInfo.grade2GirlCount,
            this.gkInfo.grade1StuCount - this.gkInfo.grade1GirlCount,
            this.gkInfo.grade0StuCount - this.gkInfo.grade0GirlCount,
          ],
          color: "#284691",
        },
        {
          name: "女生",
          type: "bar",
          stack: "总数",
          data: [
            this.gkInfo.grade2GirlCount,
            this.gkInfo.grade1GirlCount,
            this.gkInfo.grade0GirlCount,
          ],
          color: "#e1be46",
        },
      ];
      var testchart_2 = echarts.init(document.getElementById("testchart-2"));
      var option_2 = {
        title: {
          text: "大中小班男女数量",
          left: "center",
          top: "bottom",
          textStyle: {
            color: "#000",
            fontSize: 14,
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        legend: {
          data: ["男生", "女生"],
        },
        grid: {
          left: 0,
          right: 0,
          containLabel: true,
        },
        yAxis: {
          type: "value",
          axisLine: {
            lineStyle: {
              width: 0,
            },
          },
          axisLabel: {
            textStyle: {
              color: "#656565",
              fontSize: "12",
            },
            // formatter: function (value) {
            //   if (value < 1 && value !== 0) {

            //   } else {
            //     return value;
            //   }
            // }
          },
          axisTick: {
            lineStyle: {
              width: 0,
            },
          },
        },
        xAxis: {
          type: "category",
          data: ["小班", "中班", "大班"],
          axisLine: {
            lineStyle: {
              width: 0,
            },
          },
          axisLabel: {
            textStyle: {
              color: "#000",
              fontSize: "14",
            },
          },
          axisTick: {
            lineStyle: {
              width: 0,
            },
          },
        },
        series: data_2,
      };
      if (option_2 && typeof option_2 === "object") {
        testchart_2.setOption(option_2, true);
      }
    },
    echarts3(res) {
      var data = res;
      data.forEach((item) => {
        item.country = parseFloat(item.country);
        item.province = parseFloat(item.province);
        item.school = parseFloat(item.school);
      });
      var data_avgbar = [["product", "全国平均值", "全省平均值", "全园平均值"]];
      for (var i = 0; i < data.length; i++) {
        let title = data[i]["title"];
        let country = data[i]["country"];
        let province = data[i]["province"];
        let school = data[i]["school"];
        data_avgbar.push([title, country, province, school]);
      }
      var myChart_avgbar = echarts.init(document.getElementById("avgbar"));
      var option_avgbar = {
        tooltip: {},
        dataset: {
          source: data_avgbar,
        },
        legend: {
          width: 300,
          data: ["全国平均值", "全省平均值", "全园平均值"],
          right: 0,
          bottom: 60,
          orient: "vertical",
        },
        xAxis: {
          type: "category",
          axisLabel: {
            textStyle: {
              color: "#000",
              fontSize: "14",
            },
            interval: 0,
          },
          axisLine: {
            lineStyle: {
              color: "#d8d8d8",
              width: 2,
            },
          },
        },
        grid: {
          left: 20,
          right: 180,
        },
        yAxis: {
          axisLine: {
            lineStyle: {
              width: 0,
            },
          },
          axisLabel: {
            textStyle: {
              color: "#656565",
              fontSize: "12",
            },
          },
          axisTick: {
            lineStyle: {
              width: 0,
            },
          },
        },
        series: [
          {
            type: "bar",
            barWidth: 20,
            label: {
              show: true,
              position: "top",
            },
            color: "#db606f",
          },
          {
            type: "bar",
            barWidth: 20,
            label: {
              show: true,
              position: "top",
            },
            color: "#284691",
          },
          {
            type: "bar",
            barWidth: 20,
            label: {
              show: true,
              position: "top",
            },
            color: "#e1be46",
          },
        ],
      };
      if (option_avgbar && typeof option_avgbar === "object") {
        myChart_avgbar.setOption(option_avgbar, true);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.pda_report_page2 {
  width: 100%;
}
</style>